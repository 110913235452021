import { useState, useEffect, React } from "react";
import  '../css/Navbar.css';
import  '../css/Responsive.css';
import logo from '../images/TI_LOGO_ENVELOPE-removebg-preview.png'
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navbar1 = () => {
     // sticky nav
     const [color, setColor] = useState(false)

     const changeColor = () => {
         if (window.scrollY >= 78) {
             setColor(true)
         } else {
             setColor(false)
         }
     }
 
     window.addEventListener('scroll', changeColor)
     
  return (
    <div>
      
<nav className="nav-bar main-nav-bar relative">
  {/* Background Color White */}
  <div className="nav-output">
    {/* Output Navbar */}
    <div className="bg-gray">
      {/* Container  */}
      <div className="container container-nav">
        {/* Row */}
     
      </div>
    </div>
    <Navbar  expand="lg" collapseOnSelect id="nav" className={color ? "NavBar NavBar-bg" : "NavBar"}>
      <Container>
        <Navbar.Brand href="/" className="my-logo"> <img
                className="logo-two"
                src={logo}
                alt="logo"
              /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="me-auto">
            <Nav.Link href="/"><Link to="/" className="nav-link" activeClassName="active">  Home</Link></Nav.Link>
            <Nav.Link href="/About"><Link to="/About" className="nav-link" activeClassName="active" >About us</Link></Nav.Link>
      <Nav.Link href="/Product"><Link to="/Product" className="nav-link" activeClassName="active" >Products</Link></Nav.Link>
            
            <Nav.Link href="/Industries"><Link to="/Industries" className="nav-link" activeClassName="active">Industries</Link> </Nav.Link>
            <Nav.Link href="/Contact"><Link to="/Contact" className="nav-link" activeClassName="active">Contact us</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

   
    {/* <div className="nav-menu-bar">
      <div className="sticky-wrapper" style={{}}>
        <div className="sticky-navbar">
          <div className="nav-menu-main">
            <div className="container">
             
              <div className="position-inherit">
              
                <a href="#" className="navbar-toggle" id ="checkbox">
                  <span />
                  <span />
                  <span />
                </a>
               
                <ul id="main-menu" className="nav-menu">
                  <li className="nav-item has-dropdown active">
                  <Link to="/" className="nav-link">
                      Home
                      
                    </Link>
                    
                  </li>
                  <li className="nav-item has-dropdown">
                    <Link to="/About" className="nav-link">
                      About
                      
                    </Link>
                 
                  </li>
                  <li className="nav-item has-dropdown">
                    <Link to="/Service" className="nav-link">
                      Services
                      
                    </Link>
                 
                  </li>
                  
                  <li className="nav-item">
                    <Link to="/Contact" className="nav-link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </div>
</nav>

  </div>
  )
}

export default Navbar1;