import Citric from "../images/product/citric-acid.jpg";
import Citric2 from "../images/product/citric-acid-monohydrate.jpg";
import sample1 from "../doc/MELAMINE.pdf";
import sample2 from "../doc/Methanol.pdf";
import about from "../images/04-about-us.jpg";
import sample3 from "../doc/CITRIC-ACID-MONOHYDRATE.pdf";
import sample4 from "../doc/ACRYLIC ACID (1).pdf";
import sample6 from "../doc/ACETIC ACID.pdf";
import sample7 from "../doc/PHENOL.pdf";
import sample8 from "../doc/GLYCERINE.pdf";
import sample9 from "../doc/MONO ETHYLENE-GLYCOL.pdf";
import sample10 from "../doc/TOULENE.pdf";
import icon1 from "../images/thread.png";
import sample11 from "../doc/MALIEC-ANHYDRIDE.pdf";
import sample12 from "../doc/monosodium.pdf";
import sample13 from "../doc/ISOPROPYL ALCOHOL (IPA).pdf";
import sample5 from "../doc/ACETONE.pdf";
import mela1 from "../images/product/melamine.jpg";
import mela2 from "../images/product/melamine3.jpg";
import mela3 from "../images/product/melamine2.jpg";
import mela4 from "../images/product/melamine4.jpg";
import mela5 from "../images/product/melamine5.jpg";

// import methal from "../images/product/methal.jpg";
import phenol from "../images/product/phenol.jpg";
import phenol2 from "../images/product/phenol2.jpg";
import acetone from "../images/product/Acetone (1).jpg";
import toluene from "../images/product/toluene.jpg";
import toluene2 from "../images/product/toulene1.jpg";
import maleic from "../images/product/maleic-anhy.jpg";
import maleic2 from "../images/product/maleic-anhy1.jpg";
import maleic3 from "../images/product/maleic-anhy2.jpg";
import maleic4 from "../images/product/Maleic-Anhydride.jpg";
import acetic from "../images/product/acetic-acid.jpg";
import mono from "../images/product/MEG-Ethylene-Glycol.jpg";
import mono2 from "../images/product/meg1.jpg";
import acrylic4 from "../images/product/acryylic-acid.jpg";
import acrylic1 from "../images/product/acrylic-acid.jpg";
import acrylic3 from "../images/product/acrylic-acid1.jpg";
import acrylic2 from "../images/product/acrylic-acid2.jpg";
import glycerine from "../images/product/glycerine.jpg";
import glycerine1 from "../images/product/glycerine1.jpg";
import msg1 from "../images/product/monosodium.jpg";
import msg2 from "../images/product/monosodium-2.jpg";
import msg3 from "../images/product/monosodium-3.jpg";
import msg4 from "../images/product/MSG-FUFENG.jpg";
import msg5 from "../images/product/msg1.jpg";

import propyl from "../images/product/ipa.jpg";
// import propyl1 from "../images/product/iso1.jpg";
import propyl2 from "../images/product/iso2.jpg";
// import mela from "../images/product/melamine.jpg";
import methal from "../images/product/methal.jpg";
import methal1 from "../images/product/methanol.jpg";
// import Citric from "../images/product/citric-acid.jpg";

const productjs = [
  {
    "id": 1,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "ACRYLIC ACID",
    ServicePara1: sample4,
    "imgData": [
      {"productImg": acrylic1},
      {"productImg": acrylic2},
      {"productImg": acrylic3},
      {"productImg": acrylic4},
      
    ]
  },
  {
    "id": 2,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "ACETONE",
    ServicePara1: sample5,
    "imgData": [
      {"productImg": acetone},
     
    ]
  },
  {
    "id": 3,
    "category": "Paints and coatings",
    "ServiceName": "ACETIC ACID",
    ServicePara1: sample6,
    "imgData": [
      {"productImg": acetic},
     
    ]
  },
  {
    "id": 4,
    "category": "Kitchenware",
    "ServiceName": "CITRIC ACID MONOHYDRATE",
    ServicePara1: sample3,
    "imgData": [
      {"productImg": Citric},
      {"productImg": Citric2},
     
    ]
  },
  {
    "id": 5,
    "category": "Kitchenware",
    "ServiceName": "GLYCERINE",
    ServicePara1: sample8,
    "imgData": [
      {"productImg": glycerine},
      {"productImg": glycerine1},
    
    ]
  },
  {
    "id": 6,
    "category": "Kitchenware",
    "ServiceName": "ISOPROPYL ALCOHOL (IPA)",
    ServicePara1: sample13,
    "imgData": [
      {"productImg": propyl},
      // {"productImg":propyl1},
      {"productImg": propyl2}
    ]
  },
  {
    "id": 7,
    "category": "Kitchenware",
    "ServiceName": "MALEIC ANHYDRIDE",
    ServicePara1: sample11,
    "imgData": [
      {"productImg": maleic},
      {"productImg": maleic2},
      {"productImg": maleic3},
      {"productImg": maleic4},
    
    ]
  },
  {
    "id": 8,
    "category": "Laminates",
    "ServIcon": "icon1",
    "ServiceName": "MELAMINE",
    "category2": "resin",
    ServicePara1: sample1,
    "imgData": [
      {"productImg": mela1},
      {"productImg": mela3},
      {"productImg":mela2},
      {"productImg":mela4},
      {"productImg":mela5},

    ]
  },
  {
    "id": 9,
    "category": "Resin",
    "ServiceName": "MONOETHYLENE GLYCOL (MEG)",
    ServicePara1: sample9,
    "imgData": [
     
      {"productImg": mono},
      {"productImg": mono2},
    
    ]
  },
  {
    "id": 10,
    "category": "Resin",
    "ServiceName": "MONOSODIUM GLUTAMATE (MSG)",
    ServicePara1: sample12,
    "imgData": [
      {"productImg": msg1},
      {"productImg": msg2},
      {"productImg": msg3},
      {"productImg": msg4},
      {"productImg": msg5},
    ]
  },
  {
    "id": 11,
    "category": "Resin",
    "ServiceName": "METHANOL",
    ServicePara1: sample2,
    "imgData": [
      {"productImg": methal1},
   
  
    ]
  },
  {
    "id": 12,
    "category": "Food & Beverages",
    "ServiceName": "PHENOL",
    ServicePara1: sample7,
    "imgData": [
      {"productImg": phenol2},
      {"productImg": phenol},
     
     
    ]
  },
  {
    "id": 13,
    "category": "Food & Beverages",
    "ServiceName": "TOLUENE",
    ServicePara1: sample10,
    "imgData": [
      {"productImg": toluene},
      {"productImg": toluene2},
      
    ]
  }
];

export default productjs;
