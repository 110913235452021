import React, { useState, useLayoutEffect } from "react";

import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Footer from "../component/Footer.js";
import Zoom from "react-reveal/Zoom";
import textile from "../images/textile.jpg";
import food from "../images/food.jpg";
import dye from "../images/dye.jpg";
import pack from "../images/packaging.jpg";
import paint from "../images/paint.jpg";
import pharma from "../images/pharma.jpg";
import textIcon from "../images/thread.png";
import packIcon from "../images/package-box.png";
import pharm from "../images/medicine.png";
import paintIcon from "../images/paint-roller.png";
import foodIcon from "../images/fast-food.png";
import lamIcon from "../images/furniture.png";

import "../css/Service.css";
const Industries = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <section id="page2" className="header-breadcrumb">
        <div className="container">
          <div className="row pb-lg-5 pb-2">
            <div className="col-lg-6">
              <div className="headerText">
                <Zoom>
                  <span className="page-title-line">
                    We are a professional import and export trading company
                    specializing in chemical
                  </span>
                </Zoom>
                <Zoom>
                  <h1 className="page-title-heading">
                    Globally spreaded business
                  </h1>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -----------------------------features------------------ */}
      <section id="service" className="services services-two pt-70 pb-70">
        {/*========== My Services Info ==========*/}
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="section-title">
                <Zoom>
                  <h2>explore Services</h2>
                  <h3> industrial Segments </h3>
                </Zoom>
              </div>
            </div>
            <div className="col-lg-6">
              <p className="p-title-section">
                Our Strategies At Work Are The Means By Which To Achieve The
                Desired Goals, And Achieve Your Goals And Dreams Here
              </p>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}
            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${textile})` }}
              ></div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Resin</h4>
                <p>
                  {" "}
                  Trishulent is a proud supplier of chemicals and raw materials
                  to resin industries.
                </p>
                <br />{" "}
                <p>
                  Our highly technical salespeople have an extensive knowledge
                  of the different challenges encountered .We are able to remain
                  highly competitive in the resin chemical marketplace because
                  we offer the full range of services from sales and customer
                  service .
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}

            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Food and Beverages</h4>
                <p>
                  {" "}
                  As one of the world’s premier suppliers of food and beverage
                  chemicals, Trishulent – fine & specialty chemicals is ready to
                  supply your company with a wide range of sweeteners,
                  acidulants, emulsifiers, and food grade chemicals.
                </p>
                <br />{" "}
                <p>
                  {" "}
                  Trishulent works closely with manufacturers and suppliers to
                  ensure high levels of quality and selection. Trishulent offers
                  a wide variety of food and beverage chemicals that are
                  important ingredients in a myriad of products on the market
                  today. Our Emulsifiers class of products features natural
                  sweeteners, food chemicals, acidulants, food emulsifiers, and
                  food grade chemicals.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${food})` }}
              ></div>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}
            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${pack})` }}
              ></div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Kitchenware</h4>
                <p>
                  {" "}
                  we are the safest, most efficient, and environmentally
                  friendly choice for kitchen ware chemical solutions.
                </p>
                <br />{" "}
                <p>
                  For manufacturers of kitchen ware auxiliaries and technical
                  textiles, we offer a comprehensive range of products and
                  mixing and blending capabilities together with advice on
                  application. Backed by a strong worldwide network, we provide
                  you with quality products and value-added supply chain
                  solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}

            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Laminates</h4>
                <p>
                  {" "}
                  Trishulent specializes in supplying lamination and raw
                  materials used to make industrial sealants for commercial
                  applications. Without quality raw ingredients from Trishulent,
                  Lamination manufacturers would not be able to offer the
                  exclusive and high-end products we find on the market today.
                </p>
                <br />{" "}
                <p>
                  Manufacturers of industrial adhesives rely on Trishulent to
                  accurately and quickly supply both water- and solvent-based
                  adhesive chemicals in a variety of packaging options.
                  Trishulent's long-standing relationships and chemical industry
                  knowledge make us your best supplier for adhesive chemicals in
                  the India.
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${dye})` }}
              ></div>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}

            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${paint})` }}
              ></div>
            </div>

            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Paints and Coatings</h4>
                <p>
                  {" "}
                  As a supplier and distributor of painting and coating
                  chemicals, Trishulent is proud to work with the world’s
                  leading adhesives, painting and coatings, manufacturers to
                  bring you the materials you need.
                </p>
                <br />{" "}
                <p>
                  Trishulent is also proud to work as a supplier to the
                  manufacturers of adhesives by supplying the raw materials and
                  chemical ingredients that manufacturing companies trust and
                  need..
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Services Items Column */}
            <div className="col-sm-6 col-lg-6">
              <div className="content-box" id="res">
                {/* <Zoom fade down>
                    <div className="servIcon">
                      <img src={textIcon} />
                    </div>
                  </Zoom> */}
                <h4>Pharmaceuticals & APLS</h4>
                <p>
                  {" "}
                  As an active pharmaceutical ingredient and intermediate
                  supplier, Trishulent takes pride in providing a wide range of
                  high quality pharmaceutical materials at competitive market
                  prices. We work closely with API manufacturers and custom
                  formulators to ensure that the active pharmaceutical
                  ingredients you require are readily available.
                </p>
                <br />{" "}
                <p>
                  {" "}
                  Trishulent is proud to represent the best custom manufacturers
                  of active pharmaceutical ingredients with high production
                  capabilities, and we will gladly work with you to develop your
                  API and chemical business. Active pharmaceutical ingredients
                  call for a certain standard of quality and reliability that
                  you can count on when you work with API’s from Trishulent..
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-6">
              <div
                className="services-item-two "
                style={{ backgroundImage: `url(${pharma})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------------------------related products-------------------------------- */}

      <Footer />
    </div>
  );
};

export default Industries;
