import React, { useLayoutEffect } from "react";
import "../css/About.css";
import about from "../images/04-about-us.jpg";
import Footer from "../component/Footer.js";
import { Zoom } from "react-reveal";
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      {/* ----------------------page Head------- */}
      <section id="page" className="header-breadcrumb">
        <div className="container">
          <div className="row pb-lg-5 pb-2">
            <div className="col-lg-6">
              <div className="headerText">
                <Zoom>
                  <span className="page-title-line">
                    The Finest Supplier of Industrial Chemicals in India
                  </span>
                </Zoom>
                <Zoom>
                  <h1 className="page-title-heading">
                    One Stop Solution for Chemical Procurement
                  </h1>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="about-one pt-70 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-50">
                <div className="info-about2 order-lg-1 order-2">
                  <div className="section-title-left">
                    <Zoom fade left>
                      {/* <h2>about us</h2> */}
                      <h3>Get A Solution For All Industries</h3>
                    </Zoom>
                  </div>
                  <div className="row mb-10">
                    <div className="col-12">
                      <p className="mb-25 text-align-justify">
                        As a chemical supplier, we source fine and specialty
                        chemicals from our extensive network of global &
                        domestic chemical manufacturers. Trishulent provides a
                        range of import/export, specialty chemical distribution,
                        chemical logistics, and custom outsourcing services to
                        the chemical products industry. We are governed by the
                        guiding principles of innovation, resourcefulness,
                        proactiveness and accuracy.
                      </p>
                      <p className="mb-25 text-align-justify">
                        As a chemical sourcing specialist, Trishulent focuses on
                        meeting your chemical sourcing needs as quickly and as
                        reliably as possible. Our global contacts, extensive
                        network of relationships, understanding of market
                        dynamics, and in-depth chemical knowledge enable us to
                        provide customers with the products and services they
                        need to keep their businesses running smoothly.
                      </p>
                    </div>
                    {/* <div className="col-sm-6"> */}

                    {/* </div>
                    <div className="col-sm-6"> */}

                    {/* </div> */}
                  </div>

                  {/* <a href="about-us.html" className="main-btn-two  ">
                    <div className="text-btn">
                      <span className="text-btn-one">Read More</span>
                      <span className="text-btn-two">Read More</span>
                    </div>
                    <div className="arrow-btn">
                      <span className="arrow-one">
                        <i className="fa fa-caret-right" />
                      </span>
                      <span className="arrow-two">
                        <i className="fa fa-caret-right" />
                      </span>
                    </div>
                  </a>
                  <div className="call-us">
                    <span>Call us or for any inquiries</span>
                    <span className="phone-number">
                      <a href="#">+(91)22- 4004-7280</a>
                    </span>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 padding-about-one mb-50">
                <div className="about-img-two order-lg-2 order-1">
                  <img src={about} alt="about" />
                  <div className="overlay-about">
                    <div className="text-overlay">
                      <div className="pos-text">
                        <div className="number-years">48</div>
                        <span>
                          Years Of Trust <br /> And Experince
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="features pb-120" id="strength">
        <div className="container">
          <div className="row feat-two-content">
            <div className="col-md-4 no-padding">
              <div className="img-feat-two"></div>
            </div>
            <div className="col-md-8 no-padding">
              <div className="feat-why-us">
                <div className="section-title-left">
                  <Zoom fade left>
                    {/* <h2>explore features</h2> */}
                    <h3> Our Strenghts </h3>
                  </Zoom>
                </div>
                {/* <p className="mb-30">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusm
            tmpor incididunt ut labore et dolore magna aliqua. enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat adipisicing.
          </p> */}
                <div className="row">
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>40+ years of family expertise and skill</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>Long lasting customer relations</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>A legacy continued through generations</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>Infrastructure & global outreach </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>Large Inventory & Timely Supply</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div className="feat-item-two">
                      <div className="content-box-feat">
                        <i className="fa fa-circle-o" />
                        <h4>Cost effective & efficient service</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <-----------------------------our features------------------------ */}
      <div className="features">
        <div className="container">
          <div className="row ">
            <div className="section-title">
              <Zoom fade down>
                <h2>explore features</h2>
                <h3> Our Mission &amp; Values </h3>
              </Zoom>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="feat-item-one">
                <div className="content-box-feat">
                  <span className="flaticon-thinking" />
                  <Zoom fade left>
                    <h4>Our Mission</h4>
                  </Zoom>
                  <p>
                    The greatest feeling in the world is when you can deliver
                    extraordinary performance, surpassing all expectations. What
                    drives our company is the pursuit of this extraordinary
                    performance over & over again. In our company, we work with
                    clients who look for value in every transaction & every
                    interaction through our services & offerings. Procurement of
                    Bulk chemicals is an ardous venture made easy by Trishulent.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="feat-item-one">
                <div className="content-box-feat">
                  <span className="flaticon-idea" />
                  <Zoom fade left>
                    <h4>Our Values</h4>
                  </Zoom>
                  <p>
                    India is a rising giant in the world of global trade.
                    Chemical industry, further, is the lifeline of any
                    industrial nation by providing inputs, resources & end-uses
                    for a nation's economy. In the development of India as a
                    thriving economy with a well-developed chemical
                    infrastructure, Trishulent wants to be a major contributor
                    in terms of technical expertise & quality products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
