import React from 'react'
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar1 from './component/Navbar1';
import Home from './component/Home';
import About from './component/About';
import Product from './component/Product';
// import Faqs from './component/Faqs';
// import Doctors from './component/Doctors';
// import Franchise from './component/Franchise';
import Contact from './component/Contact';
import Service from './component/Service';
import Industries from './component/Industries';


const App = () => {
  return (
    <div>
      
      <BrowserRouter>
    <Navbar1/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/About' element={<About/>}/>
         <Route path='/Product' element={<Product/>} />
         {/* <Route path='/Faqs' element={<Faqs/>} />
         <Route path='/Doctors' element={<Doctors/>} />
         <Route path='/Contact' element={<Contact/>} />  */}
         <Route path='/Industries' element={<Industries/>} />
         <Route path='/Contact' element={<Contact/>} /> 
         <Route path='/service/:name' element={<Service/>} /> 
      </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App

