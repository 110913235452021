import { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Tabs from "./Tabs";
import $ from 'jquery';
import { Link } from "react-router-dom";
import "swiper/css/pagination";
// import { Document, Page } from 'react-pdf';
import "../css/Product.css";
import prodList from "../images/broch.jpg";
// import mela from "../images/product/melamine.jpg";
// import methal from "../images/product/methal.jpg";
// import Citric from "../images/product/citric-acid.jpg";
import Zoom from "react-reveal/Zoom";
import Footer from "./Footer";
import textIcon from "../images/thread.png";
import packIcon from "../images/package-box.png";
// import pharm from "../images/medicine.png";
import paintIcon from "../images/paint-roller.png";
import foodIcon from "../images/fast-food.png";
import lamIcon from "../images/furniture.png";
import pharmacy from "../images/medicine.png";
// import { useLocation } from "react-router-dom";
import Productjs from "./Productjs";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { useLayoutEffect } from "react";


SwiperCore.use([Autoplay, Pagination]);


const Product = () => {
  const swiperRefLocal = useRef()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  // const newLinkStyles = ({ isActive }) => {
  //   return {
  //     fontweight: isActive ? "red" : "white",
  //   };
  // };
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(Productjs)
  const [tabIndex, setTabIndex] = useState(0);
  // Store Category in CategoryData 
  const categoryData = Productjs.map((value) => {
    return value.category
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];


  // for all categoryData set 
  const filterCategory = (category) => {
    if (category == "all") {
      setData(Productjs)
      return;
    }
    const filteredData = Productjs.filter((value) => {
      return value.category == category;
    })

    setData(filteredData);

  }
  return (
    <div>
      <section id="page3" className="header-breadcrumb">
        <div className="container">
          <div className="row pb-lg-5 pb-2">
            <div className="col-lg-8">
              <div className="headerText">
                <Zoom>
                  <span className="page-title-line">
                    The Best Supplier of Industrial Chemical in India{" "}
                  </span>
                </Zoom>
                <Zoom>
                  <h1 className="page-title-heading">
                    Supply to various Industrial Segments
                  </h1>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------------------------------------search products-------------------- */}
      <section>
        <div className="container">
          <div className="row ">
            <Zoom>
              <div className="section-title">
                <h2>explore Products</h2>
                <h3> Our Main Products </h3>
              </div>
            </Zoom>
            {/* <div className="col-md-5">
              <Zoom>
                <div className="section-title">
                  <h2>explore Products</h2>
                  <h3> Products  </h3>
                </div>
              </Zoom>
            </div>
            <div className="col-md-5">
              <Zoom>
                <p className="p-title-section">
                  Our Strategies At Work Are The Means By Which To Achieve The
                  Desired Goals, And Achieve Your Goals And Dreams Here
                </p>
              </Zoom>
            </div> */}
          </div>
        </div>
      </section>


      <section
        className="fw bgyellow products_search_main wow  fadeInUp"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <div className="container">
          <div className="row hidden-xs">
            {/* {
              data.map((value) => {
                const { id, category, ServIcon } = value
                return (
                  <>
            <div className="col-md-2 col-xs-6"  key={id}>
              <div className="row pr product_list_icon scroll_to_link" rel="polymers">
              
                <div className="col-md-4 col-xs-12 ">
                  <div className="fwp products_search_type">
                    <img src={ServIcon} className="fw" />
                 
                  </div>
               
                </div>
              
                <div className="rightline pxr">&nbsp;</div>
              </div>
            </div>

            </>
                )
              })
            } */}

          </div>
          <div className="fwpc products_search_desc">
            <div className="col-md-12 col-md-offset-5 col-xs-7 col-xs-offset-5">
              <div className="fw">

                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <div className="fw productsearch">
                <div className="fwp">
                  <input
                    type="text"
                    placeholder="Search Products"
                    className="fw"
                    id="search_product"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                  <span className="ic_search">&nbsp;</span>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </section>
      <section>
        <div class="container">
          <div className="row visible-xs">
            <div className="col-xs-6 pd">
              <div className="row pr product_list_icon scroll_to_link pt-50" rel="polymers">
                {
                  data.map((value) => {
                    const { id, ServiceName } = value
                    return (
                      <>

                        <div className="col-lg-4 col-xs-offset-5 pd">
                          <div className="fwpc products_search_desc " >

                            <div className="fw">
                              <h4>
                                <i className="fa fa-check"></i>  <a href="javascript:void(0);">{ServiceName}</a>
                              </h4>
                            </div>
                          </div>
                        </div>

                      </>
                    )
                  })
                }

                <div className="rightline pxr">&nbsp;</div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="pt-70 pb-90">
        <div className="container">
          <div className="row pt-lg-2 pb-lg-2">
            <div className="col-md-6">
              <Zoom>
                <div className="search-box">
                  <input type="search" placeholder="search" className="searchInput" id="search_product"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                  {/* <button className="search_btn">
                  <i className="fa fa-search"></i>
                  </button> */}
                </div>

              </Zoom>
            </div>
            <div className="col-md-6">
              <Zoom>
                <div className="count d-flex justify-content-end">
                  <h2 onClick={handleShow}>View  Products</h2>
                  {/* <input type="text" placeholder="" value="100"/>  */}
                </div>
              </Zoom>
            </div>
          </div>
          <hr />
          <div className="row" >
            {
              Productjs
                .filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  }
                  else if (val.ServiceName.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())) {
                    return val;
                  }
                })
                .map((val) => {


                  // Productjs.map((elem) => {
                  //   const { id, ServiceImg, ServicePara1, ServiceName } = elem;

                  return (
                    <>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={val.id}>
                        <div className="wsk-cp-product">
                          <div className="wsk-cp-img" >
                            <Swiper
                              pagination={{ clickable: true }}
                              modules={[Autoplay, Pagination]}
                              className="projSwiper"
                            >
                              {val.imgData.map((imgValue) => (
                                <SwiperSlide>


                                  <img src={imgValue.productImg}></img>

                                </SwiperSlide>
                              ))}

                            </Swiper>


                          </div>



                        </div>
                        <div className="wsk-cp-text">
                          {/* <div className="category">

                            </div> */}
                          <div className="title-product">
                            <h3>{val.ServiceName}</h3>
                            <span>
                              <a
                                href={val.ServicePara1}
                                target="_blank"
                                rel="noreferrer"
                              >
                                view details
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

          </div>
        </div>
      </section>
      {/* <div className="programButton">
    <a className="programButtonClick" >Book Now</a>
</div> */}
      <Modal
        show={show}
        id="partner"
        backdrop="static"
        keyboard={false}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onHide={handleClose}

      >

        <Modal.Body>

          <div className="partnerProgramForm">

            <div className="formTitleSection">
              {/* <div className="d-flex">
          <h2> Brochure</h2>
          </div> */}
              <div className="closeProgram closeProgram1" onClick={handleClose}>
                {" "}

                <i className="fa fa-times" />{" "}
              </div>

            </div>
            <img src={prodList} />

          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default Product;
