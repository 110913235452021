import React,{useLayoutEffect} from 'react'
import '../css/Footer.css';
import logo from '../images/TI_LOGO_ENVELOPE-removebg-preview.png'
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import productList from "../doc/Product list.1.5_page-0001.jpg";

const Footer = () => {
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0)
  // });
  return (
    <div>
        <footer className="footer footer-default-padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 mb-30">
        <div className="footer-widget">
          <div className="footer-logo">
            <img src={logo} alt="logo"/>
          </div>
          <p className="mb-30">
          11, Haresh Chambers, 3rd Floor, 313/319, Samuel Street, Masjid Bunder (W), Mumbai, MH-400003
          </p>
          {/* Btn Two */}
          {/* <Link to="/About" className="main-btn-three">
            <div className="text-btn">
              <span className="text-btn-one">More about Us</span>
              <span className="text-btn-two">More about Us</span>
            </div>
            <div className="arrow-btn">
              <span className="arrow-one">
                <i className="fa fa-caret-right" />
              </span>
              <span className="arrow-two">
                <i className="fa fa-caret-right" />
              </span>
            </div>
          </Link> */}
        </div>
      </div>
      <div className="col-sm-6 col-lg-6 mb-30">
        <div className="footer-widget mb-0">
          <h4>Company Links</h4>
          <div className="line-footer" />
          <div className="row">
            <div className="col-lg-6">
              <ul className="footer-link mb-0">
                <li>
                  <Link to="/About">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    About Us
                  </Link>
                </li>
                <li>
                <HashLink to="/#about">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    why choose&nbsp;us
                    </HashLink>
                </li>
                <li>
                  <HashLink to="/#offer">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Services
                  </HashLink>
                </li>
                <li>
                  <Link to="/Product">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                     Products
                  </Link>
                </li>
               
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="footer-link mb-0">

              <li>
              <HashLink to="/#service">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Industrial Segment
                  </HashLink>
                </li>
                <li>
                <HashLink to="/#testimonials">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Testimonials
                  </HashLink>
                </li>
              
                <li>
                <HashLink to="/#clients">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Our clients
                  </HashLink>
                </li>
                <li>
                  <Link to="/Contact">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Contact Us
                  </Link>
                </li>
                <li className="d-none">
                  <Link to={productList}>
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                  Product List
                  </Link>
                </li>
                {/* <li>
                  <a href="our-pricing.html">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Our Pricing
                  </a>
                </li>
                <li>
                  <a href="services-one.html">
                    <span>
                      <i className="fa fa-angle-right" />
                    </span>{" "}
                    Our Services
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mb-30">
        <div className="footer-widget">
        <h3>Google Map</h3>
          <div className="line-footer" />
         
          <iframe
                  id="map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15094.221873684464!2d72.8359513!3d18.9510606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9a7f60557e81d38b!2sTrishulent%20India!5e0!3m2!1sen!2sin!4v1675149564424!5m2!1sen!2sin"
                  // width={300}
                  // height={200}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex={0}
                />
        </div>
      </div>
    </div>
    {/* <div className="row row-contact">
      <div className="col-lg-4 col-sm-6 no-padding">
        <div className="single-item">
          <i className="fa fa-phone"/>
          <p>  022 4004 7280</p>
              <p>+91 8878879401     </p>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 no-padding">
        <div className="single-item">
          <i className="fa fa-envelope" />
          <p> info@trishulent.com    </p>

<p>sales@trishulent.com   </p>
        </div>
      </div>
      <div className="col-lg-4 col-sm-12 no-padding">
        <div className="single-item">
          <i className="fa fa-map-marker" />
          <p>11, Haresh Chambers, 3rd Floor, 313/319, Samuel Street, Masjid Bunder (W), Mumbai ,Maharashtra-400003 (India)  </p>
        </div>
      </div>
    </div> */}
  </div>
 
</footer>
<div className="footer-bar">
    <div className="container">
      <div className="footer-copy">
        <div className="row">
          <div className="col-md-6">
            {/* Copyright By Me NourEgy  */}
            <div className="copyright">
              © 2023 All Rights Reserved By &nbsp;
              <a href="" target="_blank">
              Trishulent India
              </a>
            </div>
          </div>
          <div className="col-md-6">
          <div className="copyright">
              Developed by &nbsp;
              <a href="" target="_blank">
              Shree Krishna Digital Marketing
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Footer