import textile from "../images/textile.jpg";
import food from "../images/food.jpg";
import dye from "../images/dye.jpg";
import pack from "../images/packaging.jpg";
import paint from "../images/paint.jpg";
import pharma from "../images/pharma.jpg";
import textIcon from "../images/thread.png";
import packIcon from "../images/package-box.png";
import pharm from "../images/medicine.png";
import paintIcon from "../images/paint-roller.png";
import foodIcon from "../images/fast-food.png";
import lamIcon from "../images/furniture.png";

const servicejs = [
    {
        "id": 1,
        "ServiceIcon": textIcon,
        "ServiceName": "Resin",
        ServicePara1: "Trishulent is a proud supplier of chemicals and raw materials to resin industries.",
        ServicePara2:"Our highly technical salespeople have an extensive knowledge of the different challenges encountered .We are able to remain highly competitive in the resin chemical marketplace because we offer the full range of services from sales and customer service .",
        ServiceImg: textile,

    },
    {
        "id": 2,
        "ServiceIcon": foodIcon,
        "ServiceName": "Food and Beverages",
        ServicePara1: " As one of the world’s premier suppliers of food and beverage chemicals, Trishulent – fine & specialty chemicals is ready to supply your company with a wide range of sweeteners, acidulants, emulsifiers, and food grade chemicals.",
        ServicePara2: " Trishulent works closely with manufacturers and suppliers to ensure high levels of quality and selection. Trishulent offers a wide variety of food and beverage chemicals that are important ingredients in a myriad of products on the market today.  Our Emulsifiers class of products features natural sweeteners, food chemicals, acidulants, food emulsifiers, and food grade chemicals.",
     
      ServiceImg: food,
    },
    {
        "id": 3,
        "ServiceIcon": packIcon,
        "ServiceName": "Kitchenware",
        ServicePara1: " we are the safest, most efficient, and environmentally friendly choice for kitchen ware chemical solutions. ",
        ServicePara2:"For manufacturers of kitchen ware auxiliaries and technical textiles, we offer a comprehensive range of products and mixing and blending capabilities together with advice on application. Backed by a strong worldwide network, we provide you with quality products and value-added supply chain solutions.",
        ServiceImg: pack,


    },

    {
        "id": 4,
        "ServiceIcon": lamIcon,
        "ServiceName": "Laminates",
        ServicePara1: "Trishulent specializes in supplying lamination and raw materials used to make industrial sealants for commercial applications. Without quality raw ingredients from Trishulent, Lamination manufacturers would not be able to offer the exclusive and high-end products we find on the market today.",
        ServicePara2: "Manufacturers of industrial adhesives rely on Trishulent to accurately and quickly supply both water- and solvent-based adhesive chemicals in a variety of packaging options. Trishulent's long-standing relationships and chemical industry knowledge make us your best supplier for adhesive chemicals in the India.",
        ServiceImg: dye,
    },
    {
        "id": 5,
        "ServiceIcon": paintIcon,
        "ServiceName": "Paints & Coatings",
        ServicePara1: " As a supplier and distributor of painting and coating chemicals, Trishulent is proud to work with the world’s leading adhesives, painting and coatings, manufacturers to bring you the materials you need.",
        ServicePara2:"Trishulent is also proud to work as a supplier to the manufacturers of adhesives by supplying the raw materials and chemical ingredients that manufacturing companies trust and need.",
        ServiceImg: paint,
    },
    {
        "id": 6,
        "ServiceIcon": pharm,
        "ServiceName": "Pharmaceuticals & APLS",
        ServicePara1: " As an active pharmaceutical ingredient and intermediate supplier, Trishulent takes pride in providing a wide range of high quality pharmaceutical materials at competitive market prices. We work closely with API manufacturers and custom formulators to ensure that the active pharmaceutical ingredients you require are readily available.",
        ServicePara2:" Trishulent is proud to represent the best custom manufacturers of active pharmaceutical ingredients with high production capabilities, and we will gladly work with you to develop your API and chemical business. Active pharmaceutical ingredients call for a certain standard of quality and reliability that you can count on when you work with API’s from Trishulent.",
        ServiceImg: pharma,
    },

];

export default servicejs;
